"use client";

import React, { useState, useEffect } from "react";
import { CustomTextField } from "../index";
import { Box, Chip, Autocomplete } from "@mui/material";

interface CustomTagInputComponentProps {
  controldisabled?: boolean;
  onDataChanged: any;
  inputData?: any[];
  maxHeight?: any;
  label?: string;
  //textfiled
  errors?: any;
  helperText?: string;
}

export const CustomTagInputComponent = (
  props: CustomTagInputComponentProps
) => {
  const {
    controldisabled,
    inputData,
    onDataChanged,
    maxHeight,
    label,
    errors,
    helperText,
  } = props;
  const [tags, SetTags] = useState(inputData ? inputData : []);

  const handleChange = (val: any[]) => {
    SetTags(val);
  };

  useEffect(() => {
    if (inputData && inputData.length > 0) {
      SetTags(inputData);
    }
  }, [inputData]);

  useEffect(() => {
    if (tags) {
      onDataChanged(tags);
    }
  }, [SetTags, tags]);

  return (
    <Autocomplete
      options={[]}
      disabled={controldisabled}
      freeSolo
      multiple
      value={tags}
      PaperComponent={Box}
      onChange={(event, val: any[]) => handleChange(val)}
      renderTags={(value, rProps) =>
        value.map((option, index) => (
          <Chip
            label={option}
            {...rProps({ index })}
            key={index}
            size="small"
          />
        ))
      }
      renderInput={(params) => (
        <CustomTextField
          label={label}
          error={errors}
          helperText={helperText}
          {...params}
        />
      )}
    />
  );
};

export default CustomTagInputComponent;
